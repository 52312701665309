<template>
  <scrollView :pageInfo="pageInfo" @loadNewData="loadData">
    <!-- <div class="nqFrame" v-for="exo in shipList" :key="exo.id">
      <div class="flex">
        <span>订单号：{{ exo.orderNum }}</span>
        <button class="nq-button chakan-btn" @click="yanhuo(exo)">验货</button>
      </div>
    </div> -->
  </scrollView>
</template>
<script>
// import { yanhuoInfo } from '../../utils/coupon.js'
export default {
  name: 'Yanhuo',
  data() {
    return {
      // shipList: [],
      // msgTilte: '订单详情',
      // orderDetailVisible: false,
      // orderDetailInfo: {},
      // pageInfo: {
      //   pageNum: 1,
      //   pageSize: 20,
      //   hasNextPage: true
      // }
    }
  },
  created() {
    this._getOrderList()
  },
  methods: {
    // loadData(pageInfo) {
    //   this.pageInfo = pageInfo
    //   this._getOrderList()
    // },
    // _getOrderList() {
    //   const params = {
    //     pageNum: this.pageInfo.pageNum,
    //     pageSize: this.pageInfo.pageSize
    //   }
    //   this.$reqPost(yanhuoInfo.list, params).then((res) => {
    //     if (res.data.code === 200) {
    //       this.shipList = [...this.shipList, ...res.data.data.list]
    //       this.pageInfo.hasNextPage = res.data.data.hasNextPage
    //       // console.log(res.data.data.list)
    //     }
    //   })
    // },
    // yanhuo(item) {
    //   // console.log(item);
    //   this.$router.push('/inspection/' + item.orderNum)
    // },
    // toPage(url) {
    //   this.$router.push(url)
    // }
  }
}
</script>
<style lang="less" scoped>
// table {
//   width: 100%;
//   word-wrap: break-word;
//   word-break: break-all;
//   text-align: left;
//   border-bottom: 1px solid #dddddd;
//   border-left: 1px solid #dddddd;
//   th {
//     font-weight: normal;
//     border-top: 1px solid #dddddd;
//     border-right: 1px solid #dddddd;
//   }
//   td {
//     border-top: 1px solid #dddddd;
//     border-right: 1px solid #dddddd;
//     word-break: wrap;
//     min-width: 80px;
//   }
// }
// h2 {
//   font-size: 18px;
//   margin: 10px 0px;
// }
// /deep/ .nqFrame .flex{
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   line-height: 40px;
// }
// @import url("./order.less");
</style>
