import { render, staticRenderFns } from "./Fukuan.vue?vue&type=template&id=733af413&scoped=true&"
import script from "./Fukuan.vue?vue&type=script&lang=js&"
export * from "./Fukuan.vue?vue&type=script&lang=js&"
import style0 from "./Fukuan.vue?vue&type=style&index=0&id=733af413&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733af413",
  null
  
)

export default component.exports