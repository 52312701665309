<template>
  <scrollView :pageInfo="pageInfo" @loadNewData="loadData">
    <div class="nqFrame" v-for="exo in shipList" :key="exo.id">
      <div class="flex">
        <span>订单号：{{ exo.orderNo }}</span>
        <span>实重：{{ exo.netWeight }}</span>
        <span>体积：{{ exo.volume }}</span>
        <span>打包完成时间：{{ exo.packTime }}</span>
        <span>备注：{{ exo.buyNote }}</span>
        <span>状态：{{ exo.condition }}</span>
      </div>
      <div
        style="display: flex; justify-content: space-between; margin-top: 10px"
      >
        <div v-if="exo.payStateCode !== '1'"></div>

        <template v-if="exo.payStateCode === '1'">
          <span v-if="$baseInfo.IS_CF===1" style="padding-top:10px">联系淘宝客服支付</span>
          <button class="nq-button chakan-btn" @click="payMoney(exo)" v-else>
            去付款
          </button>
        </template>
    
        <button
          class="nq-button chakan-btn"
          style="background: rgb(248, 132, 0)"
          @click="seeDetails(exo.id)"
        >
          查看详情
        </button>
      </div>
    </div>
    <nqDialog
      title="订单详情"
      :visible="orderDetailVisible"
      @close="orderDetailVisible = false"
    >
      <h3>
        订单号：<span>{{ orderDetailInfo.orderNumber }}</span>
      </h3>
      <h2>收货信息</h2>
      <table>
        <tr>
          <th>{{$baseInfo.COMPANY_TITLE}}转运单号</th>
          <th>收货人</th>
          <th>地址</th>
          <th>电话</th>
        </tr>
        <tr v-if="orderDetailInfo.deliveryInfoPOJO">
          <td>{{ orderDetailInfo.deliveryInfoPOJO.shipNo }}</td>
          <td>{{ orderDetailInfo.deliveryInfoPOJO.receiver }}</td>
          <td>{{ orderDetailInfo.deliveryInfoPOJO.address }}</td>
          <td>{{ orderDetailInfo.deliveryInfoPOJO.tel }}</td>
        </tr>
      </table>
      <h2>订单备注</h2>
      <p>{{ orderDetailInfo.remark }}</p>
      <h2>订单信息</h2>
      <table>
        <tr>
          <th>物流单号</th>
          <th>重量</th>
          <th>入库时间</th>
          <th>备注</th>
        </tr>
        <tr v-for="item in orderDetailInfo.stockManages" :key="item.id">
          <td>{{ item.oddNumber }}</td>
          <td>{{ item.weight }}</td>
          <td>{{ item.createTime }}</td>
          <td>{{ item.note }}</td>
        </tr>
      </table>
      <h2>货物申报明细</h2>
      <p>
        {{ orderDetailInfo.declarationDetails }}
      </p>

      <h2>操作记录</h2>
      <table>
        <tr>
          <th>ID</th>
          <th>操作记录</th>
          <th>操作用户</th>
          <th>操作代码</th>
          <th>操作时间</th>
        </tr>
        <tr v-for="item in orderDetailInfo.logManages" :key="item.id">
          <td>
            {{ item.id }}
          </td>
          <td>
            {{ item.operationContent }}
          </td>
          <td>{{ item.operationName }}</td>
          <td>{{ item.operationId }}</td>
          <td>{{ item.createTime }}</td>
        </tr>
      </table>
      <div class="payment-bottom" style="text-align: center; margin-top: 20px">
        <button class="nq-button" @click="orderDetailVisible = false">
          关闭
        </button>
      </div>
    </nqDialog>
  </scrollView>
</template>
<script>
import { shippedInfo } from "../../utils/coupon.js";
import { webLog } from "../../utils/apis/apis";
export default {
  name: "Fayun",
  data() {
    return {
      shipList: [],
      orderDetailVisible: false,
      orderDetailInfo: {},
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
        hasNextPage: true,
      },
    };
  },
  created() {
    this._getOrderList();
  },
  methods: {
    loadData(pageInfo) {
      this.pageInfo = pageInfo;
      this._getOrderList();
    },
    _getOrderList() {
      let beginTime = new Date().getTime();
      this.$reqPost(shippedInfo.list, {
        type: "1",
        ...this.pageInfo,
      }).then((res) => {
        if (res.data.code === 200) {
          this.shipList = [...this.shipList, ...res.data.data.list];
          this.pageInfo.hasNextPage = res.data.data.hasNextPage;
        }
        let endTime = new Date().getTime();
        this.$reqPost(webLog.addLog, {
          content: `接口：${shippedInfo.list}，code:${
            res.data.code
          },响应时间：${endTime},响应时长：${endTime - beginTime}用户名：${
            JSON.parse(localStorage.userInfo).id
          }, 返回数据长度：${res.data.data.list.length}`,
        });
      });
    },
    payMoney(item) {
      console.log(item);
      this.$router.push("/to-pay/" + item.id + "/" + item.orderNo);
    },
    seeDetails(id) {
      this.orderDetailVisible = true;
      this.$reqGet(shippedInfo.item + "?id=" + id).then((res) => {
        if (res.data.code === 200) {
          this.orderDetailInfo = res.data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
table {
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  text-align: left;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  th {
    font-weight: normal;
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }
  td {
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    word-break: wrap;
    min-width: 80px;
  }
}
h2 {
  font-size: 18px;
  margin: 10px 0px;
}
@import url("./order.less");
</style>