<template>
  <scrollView :pageInfo="pageInfo" @loadNewData="loadData">
    <div class="nqFrame" v-for="wtn in shipList" :key="wtn.id">
      <div class="flex">
        <span>订单号：{{ wtn.orderNo }}</span>
        <span>{{$baseInfo.COMPANY_TITLE}}国际单号：{{ wtn.internationalNo }}</span>
        <span>渠道/国家：{{ wtn.channel + "/" + wtn.nation }}</span>
        <span>出单时间：{{ wtn.orderTime }}</span>
        <span>计费重量：{{ wtn.chargeableWeight }}</span>
        <span>运费：{{ wtn.freight }}</span>
        <!-- <div v-for="exok in wtn.packageWeights" :key="exok.id">
          <span>物流单号{{ exok.deliverNum }}</span>
        </div> -->
      </div>
      <div
        style="display: flex; justify-content: space-between; margin-top: 10px"
      >
        <button class="nq-button chakan-btn" @click="wuGuiji(wtn)">
          物流轨迹
        </button>
        <button
          class="nq-button chakan-btn"
          style="background: rgb(248, 132, 0)"
          @click="seeDetails(wtn.id)"
        >
          查看详情
        </button>
      </div>
    </div>
    <nqDialog
      :title="msgTilte"
      :visible="orderDetailVisible"
      @close="orderDetailVisible = false"
    >
      <template v-if="msgTilte === '已付款说明'">
        <div>
          <div style="padding: 8px; font-size: 14px">
            <p>
              1、您付款完成后，在工作时间范围内我们将很快为您出单，单号给出后您可以在{{$baseInfo.COMPANY_TITLE}}官网或相应物流公司官网上查询物流信息。
            </p>
            <p>2、您的货物单号给出后，将通过微信和短信通知给您，请注意查收</p>
            <p>3、有任何物流上的疑问，请咨询客服</p>
          </div>
          <div style="text-align: center; margin-top: 30px">
            <button class="nq-button" @click="orderDetailVisible = false">
              关闭
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <h3>
          订单号：<span>{{ orderDetailInfo.orderNumber }}</span>
        </h3>
        <h2>收货信息</h2>
        <table>
          <tr>
            <th>{{$baseInfo.COMPANY_TITLE}}转运单号</th>
            <th>收货人</th>
            <th>地址</th>
            <th>电话</th>
          </tr>
          <tr v-if="orderDetailInfo.deliveryInfoPOJO">
            <td>{{ orderDetailInfo.deliveryInfoPOJO.shipNo }}</td>
            <td>{{ orderDetailInfo.deliveryInfoPOJO.receiver }}</td>
            <td>{{ orderDetailInfo.deliveryInfoPOJO.address }}</td>
            <td>{{ orderDetailInfo.deliveryInfoPOJO.tel }}</td>
          </tr>
        </table>
        <h2>订单备注</h2>
        <p>{{ orderDetailInfo.remark }}</p>
        <h2>订单信息</h2>
        <table>
          <tr>
            <th>物流单号</th>
            <th>重量</th>
            <th>入库时间</th>
            <th>备注</th>
          </tr>
          <tr v-for="item in orderDetailInfo.stockManages" :key="item.id">
            <td>{{ item.oddNumber }}</td>
            <td>{{ item.weight }}</td>
            <td>{{ item.createTime }}</td>
            <td>{{ item.note }}</td>
          </tr>
        </table>
        <h2>货物申报明细</h2>
        <p>
          {{ orderDetailInfo.declarationDetails }}
        </p>

        <h2>操作记录</h2>
        <table>
          <tr>
            <th>ID</th>
            <th>操作记录</th>
            <th>操作用户</th>
            <th>操作代码</th>
            <th>操作时间</th>
          </tr>
          <tr v-for="item in orderDetailInfo.logManages" :key="item.id">
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.operationContent }}
            </td>
            <td>{{ item.operationName }}</td>
            <td>{{ item.operationId }}</td>
            <td>{{ item.createTime }}</td>
          </tr>
        </table>
        <div
          class="payment-bottom"
          style="text-align: center; margin-top: 20px"
        >
          <button class="nq-button" @click="orderDetailVisible = false">
            关闭
          </button>
        </div>
      </template>
    </nqDialog>
    <div style="text-align: center">
      <button class="nq-button lala" @click="packment">
        点击查看已付款说明
      </button>
    </div>
  </scrollView>
</template>
<script>
import { shippedInfo } from "../../utils/coupon.js";
import { webLog } from "../../utils/apis/apis";
export default {
  name: "Fukuan",
  data() {
    return {
      shipList: [],
      deliverNumList: [], // 物流单号
      msgTilte: "订单详情",
      orderDetailVisible: false,
      orderDetailInfo: {},
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
        hasNextPage: true,
      },
    };
  },
  created() {
    this._getOrderList();
  },
  methods: {
    loadData(pageInfo) {
      this.pageInfo = pageInfo;
      this._getOrderList();
    },
    _getOrderList() {
      let beginTime = new Date().getTime();
      this.$reqPost(shippedInfo.list, {
        type: "2",
        ...this.pageInfo,
      }).then((res) => {
        if (res.data.code === 200) {
          this.shipList = [...this.shipList, ...res.data.data.list];
          res.data.data.list.map((item) => {
            this.deliverNumList = [
              ...this.deliverNumList,
              ...item.packageWeights,
            ];
          });
          this.pageInfo.hasNextPage = res.data.data.hasNextPage;
        }
         let endTime = new Date().getTime(); 
          this.$reqPost(webLog.addLog, {
            content: `接口：${shippedInfo.list}，code:${res.data.code},响应时间：${endTime},响应时长：${endTime - beginTime}用户名：${JSON.parse(localStorage.userInfo).id}, 返回数据长度：${res.data.data.list.length
            }`
          });
      });
    },
    wuGuiji(wtn) {
      console.log(wtn);
      this.$router.push({
        path: "/logistics", 
        query: {
          deliverNumList: wtn.packageWeights
            .map((item) => item.deliverNum)
            .join("-"),
          orderNo: wtn.orderNo
        },
      });
    },
    seeDetails(id) {
      this.msgTilte = "订单详情";
      this.orderDetailVisible = true;
      this.$reqGet(shippedInfo.item + "?id=" + id).then((res) => {
        if (res.data.code === 200) {
          this.orderDetailInfo = res.data.data;
        }
      });
    },
    packment() {
      this.msgTilte = "已付款说明";
      this.orderDetailVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
table {
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  text-align: left;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  th {
    font-weight: normal;
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }
  td {
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    word-break: wrap;
    min-width: 80px;
  }
}
h2 {
  font-size: 18px;
  margin: 10px 0px;
}
@import url("./order.less");
</style>
