<template>
  <div class="order-management">
    <pageBread
      :icon="() => require('../../assets/image/zhuye.png')"
      text="订单管理"
    ></pageBread>

    <div class="center-order">
      <PageTab
        style="height: calc(100vh - 190px)"
        @change="tabChange"
        :current="tabCurrent"
        :tabs="[
          {
            label: '我的仓库',
            value: 1,
          },
          {
            label: '待发运',
            value: 2,
          },
          {
            label: '已付款',
            value: 3,
          },
        ]"
      >
        <!-- 放第一栏内容 -->
        <myWareHouse v-show="tabCurrent === 0"></myWareHouse>

        <!-- 放第二栏内容 -->
        <!-- <Yanhuo v-show="tabCurrent === 1"></Yanhuo> -->

        <!-- 放第三栏内容 -->
        <Fayun v-show="tabCurrent === 1"></Fayun>

        <!-- 放第四栏内容 -->
        <Fukuan v-show="tabCurrent === 2"></Fukuan>
      </PageTab>
    </div>
  </div>
</template>
<script>
import { orderInfo, shippedInfo } from '../../utils/coupon.js'
import myWareHouse from './myWareHouse.vue'
import Fayun from './Fayun.vue'
import Fukuan from './Fukuan.vue'
import Yanhuo from './Yanhuo.vue'
import { htmlLoginAndRegist, accessToken } from '../../utils/apis/apis.js'
export default {
  components: { myWareHouse, Yanhuo, Fayun, Fukuan },
  name: 'login',
  data() {
    return {
      userCenterInfo: {},
      tabCurrent: 0,
      activeName: '',
      msgTilte: '',
      tabName: '我的仓库',
      showDetail: false,
      megVisible: false,
      dictName: '',
      formValue: {
        oddNum: ''
      },

      shipList: [
        {
          orderNo: '',
          netWeight: '',
          volume: '',
          packTime: '',
          buyNote: '',
          condition: ''
        }
      ],
      payList: [
        {
          orderNo: '',
          internationalNo: '',
          channel: '',
          nation: '',
          orderTime: '',
          netWeight: '',
          freight: ''
        }
      ],
      total: 0,
      pageNum: 1,
      pageSize: 15,
      parentList: [],
      id: '',
      firstCode: ''
    }
  },
  created() {
    this.tabCurrent = this.$route.params.tab
      ? Number(this.$route.params.tab)
      : 0

    const wxCode = this.$route.query.code
    if (wxCode) {
      if (localStorage.userInfo) {
        this.getUserInfo()
      } else {
        this.$reqGet(accessToken.getWeiXinInfo + '?code=' + wxCode).then(
          (res) => {
            if (res.data.code === 200) {
              localStorage.userInfo = JSON.stringify(res.data.data)
              setTimeout(() => {
                this.getUserInfo()
              }, 10)
            }
          }
        )
      }
    } else {
      this.getUserInfo()
    }
  },
  mounted() {},
  methods: {
    getUserInfo() {
      this.$reqGet(htmlLoginAndRegist.getMemberInfo).then((res) => {
        if (res.data.code === 200) {
          this.userCenterInfo = res.data.data
          this.firstCode = res.data.data.evaIntegral
          localStorage.userInfo = JSON.stringify(res.data.data)
          this._getOrderList()
        }
      })
    },
    _getOrderList() {
      const param = {
        type: '2',
        userId: '',
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.$reqPost(shippedInfo.list, param).then((res) => {
        if (res.data.code === 200) {
          this.payList = res.data.data.list
        }
      })
    },
    // 选择物流公司
    selectExpress() {
      const params = {
        id: this.id
      }
      console.log(params)
      this.$reqGet(orderInfo.CompanyList, params).then((res) => {
        if (res.data.code === 200) {
          this.parentList = res.data.data
        }
      })
    },
    // 到货预报
    goOrder() {
      // const params = {
      //   courierCompanyCode: this.id,
      //   oddNum: this.formValue.oddNum
      // };
      // console.log(params);
      // this.$reqPut(orderInfo.addForecast, params).then((res) => {
      //   if (res.data.code === 200) {
      //   }
      // });
    },
    see() {
      this.msgTilte = '提交打包说明'
      this.megVisible = true
    },

    prediction() {
      this.msgTilte = '到货预报'
      this.megVisible = true
    },

    seeDetails() {
      this.msgTilte = '待处理'
      this.megVisible = true
    },

    close() {
      this.megVisible = false
    },
    tabChange(index, item) {
      this.tabCurrent = index
    },
    currentPageChange(pageNum) {
      this.pageNum = pageNum
    },
    toPage(url) {
      console.log(url)
      this.$router.push(url)
    }
  }
}
</script>
<style lang="less" scoped>
@import url("./order.less");
</style>
