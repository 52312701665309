var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-management"},[_c('pageBread',{attrs:{"icon":function () { return require('../../assets/image/zhuye.png'); },"text":"订单管理"}}),_c('div',{staticClass:"center-order"},[_c('PageTab',{staticStyle:{"height":"calc(100vh - 190px)"},attrs:{"current":_vm.tabCurrent,"tabs":[
        {
          label: '我的仓库',
          value: 1,
        },
        {
          label: '待发运',
          value: 2,
        },
        {
          label: '已付款',
          value: 3,
        } ]},on:{"change":_vm.tabChange}},[_c('myWareHouse',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabCurrent === 0),expression:"tabCurrent === 0"}]}),_c('Fayun',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabCurrent === 1),expression:"tabCurrent === 1"}]}),_c('Fukuan',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabCurrent === 2),expression:"tabCurrent === 2"}]})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }