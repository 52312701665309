<template>
  <div style="height: 100%">
    <ScrollView
      :page-info="pageInfo"
      @loadNewData="(pageInfo) => tabLoad('1', pageInfo)"
    >
      <div class="tp">
        <span>
          <input
            v-model="checkAll"
            type="checkbox"
            style="width: 15px; height: 15px; margin-right: 8px"
            @change="allCheckboxChange"
          >全选
        </span>
        <span>以下所有包裹总重量为：{{ totalWeight }}KG</span>
      </div>
      <div v-for="(item, index) in frameList" :key="item.id" class="nqFrame">
        <div style="margin-bottom: 10px">
          <input
            v-if="item.stateCode !== 'yb'"
            v-model="item.checked"
            :disabled="item.stateCode === 'yb'"
            type="checkbox"
            style="width: 15px; height: 15px; margin-right: 8px"
            @change="itemInputChange(item)"
          >

          国内单号：<span>{{ item.oddNumber + item.stateName }}</span>
        </div>
        <div class="ware-house-item">
          <span>重量：{{ item.weight }}KG</span>
          <span>入库时间：{{ item.createTime }}</span>
        </div>
        <div class="ware-house-item">
          <span>库存天数：{{ item.stockDays }}</span>
          <span>备注：{{ item.note }}</span>
        </div>
        <div style="text-align: right">
          <button
            v-if="item.stateCode === 'yb'"
            class="nq-button"
            style="padding: 3px 10px"
            @click="deleteItem(item, index)"
          >
            删除
          </button>
        </div>
      </div>
      <div style="text-align: center; margin: 20px 0px">
        <button class="nq-button" @click="see()">点击查看提交打包说明</button>
      </div>
    </ScrollView>

    <div class="bottom-order">
      <button class="nq-button" @click="prediction()">到货预报</button>
      <button class="nq-button copy-button" @click="submit()">提交打包</button>
    </div>

    <nqDialog
      :title="msgTilte"
      :visible="megVisible"
      @close="megVisible = false"
    >
      <!-- 弹框一 -->
      <template v-if="msgTilte === '关于提交打包说明'">
        <div class="yiqing">
          <p>
            1、由于国际物流的特殊性，进入仓库的包裹视为同意我公司验货，如您提交的包裹是非普货。
          </p>
          <p>
            2、提交打包前请注意您的包裹是否全部到齐，在打包完成后拆包或者重复打包将会产生额外的打包费用。
          </p>
          <p>3、易碎物品、贵重物品等请在提交打包后中的备注说明中写明。</p>
          <p>
            4、工作时间范围内（周一到周五10：00-17：00
            周六10：00-14：00），排队、出库、打包等整体环节一般在5小时内可以完成
          </p>
          <p>
            5、如某个或某些包裹需要国内退货，请与客服联系或者登录电脑版官网自助操作。
          </p>
        </div>
        <div style="text-align: center; margin-top: 30px">
          <button
            class="nq-button guanbi-button"
            style="padding: 8px 20px; margin-right: 30px"
            @click="megVisible = false"
          >
            关闭
          </button>
          <button
            class="nq-button copy-but"
            style="padding: 8px 20px"
            @click="goOn"
          >
            继续提交
          </button>
        </div>
      </template>
      <template v-if="msgTilte === '提交打包说明'">
        <div style="padding: 8px; font-size: 14px">
          <p>
            1.亲，烦请认真核对包裹数量和单号，如发现异常请联系客服，或者根据单号到会员中心“包裹认领”处查看！
          </p>
          <p>2.提交包裹，如果有特别要求麻烦请做好详细备注。</p>
          <p>3.包裹进行品名申报时，尽可能详尽，我司不接任何违禁品！</p>
          <p>
            4.我司拒绝收寄一切高价值产品，如通过瞒报漏报等方式擅自邮寄导致的损失，我司将不做任何赔偿，其它可以参阅我司“免责声明”和“承运契约条款”。
          </p>
          <p>5.对于含电池，含品牌（奢饰品品牌），含液体货物一定要如实申报。</p>
          <p>感谢大家支持，祝您转运愉快</p>
        </div>
        <div style="text-align: center; margin-top: 30px">
          <button class="nq-button guanbi-button" @click="megVisible = false">
            关闭
          </button>
        </div>
      </template>
      <template v-if="msgTilte === '到货预报'">
        <div class="prediction">
          <nqForm
            ref="wlform"
            :values="{}"
            :form-list="[
              {
                label: '物流方式',
                key: 'courierCompanyCode',
                type: 'select',
                placeholder: '请选择物流方式',
                options: parentList.map((item) => {
                  return {
                    label: item.dictName,
                    value: item.id,
                  };
                }),
              },
              {
                label: '物流单号',
                key: 'oddNum',
                type: 'input',
                placeholder: '请输入物流单号',
              },
            ]"
            @submit="wlSubmit"
          />
          <div class="notes">
            <p>
              一般情况下，您的物流只要写了入库码，我们将会帮您入库，无需您到货预报。
            </p>
            <p>
              由于物流签收后入库需要时间，当您的物流已经签收后过了1天仍未在此显示，或者您没有写入库码，则使用到货预报功能，后台会帮您查询并尽快安排入库。
            </p>
          </div>
        </div>
        <div style="text-align: center; margin-top: 30px">
          <button
            v-loading="submitLoading"
            class="nq-button copy-but"
            @click="goOrder"
          >
            继续提交
          </button>
        </div>
      </template>
    </nqDialog>
  </div>
</template>
<script>
import { orderInfo } from '../../utils/coupon.js'
import { webLog } from '../../utils/apis/apis'
export default {
  name: 'MyWareHouse',
  data() {
    return {
      frameList: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true
      },
      totalWeight: 0,
      msgTilte: '',
      megVisible: false,
      wlid: '',
      parentList: [],
      submitLoading: false,
      checkAll: false,
      checkedWareHouse: [],
      oddNum: ''
    }
  },
  created() {
    this._getList(this.pageInfo)
    this.$reqGet(orderInfo.CompanyList).then((res) => {
      if (res.data.code === 200) {
        this.parentList = res.data.data
      }
    })
  },
  methods: {
    tabLoad(index, pageInfo) {
      this._getList(pageInfo)
    },
    _getList(data) {
      const beginTime = new Date().getTime()
      this.$reqPost(orderInfo.MemberId, data).then((res) => {
        if (res.data.code === 200) {
          this.frameList = [...this.frameList, ...res.data.data.pageInfo.list]
          this.pageInfo.hasNextPage = res.data.data.pageInfo.hasNextPage
          this.totalWeight = res.data.data.allWeight
        }
        // 请求日志
        const endTime = new Date().getTime()
        this.$reqPost(webLog.addLog, {
          content: `接口：${orderInfo.MemberId}，code:${
            res.data.code
          },响应时间：${endTime},响应时长：${endTime - beginTime}用户名：${
            JSON.parse(localStorage.userInfo).id
          }, 返回数据长度：${res.data.data.pageInfo.list.length}， 页面：${
            this.$route.path
          }`
        })
      })
    },
    see() {
      this.msgTilte = '提交打包说明'
      this.megVisible = true
    },
    prediction() {
      this.msgTilte = '到货预报'
      this.megVisible = true
    },
    goOrder() {
      this.$refs.wlform.submitForm()
    },
    wlSubmit(params) {
      this.submitLoading = true
      this.$reqPut(orderInfo.addForecast, params).then((res) => {
        this.submitLoading = false
        if (res.data.code === 200) {
          this.$toast.success('添加成功')
          this.megVisible = false
          window.location.reload()
        } else {
          this.$toast.success(res.data.message)
        }
      })
    },
    allCheckboxChange() {
      this.frameList.forEach((item) => {
        item.checked = this.checkAll && item.stateCode === 'rk'
      })
      this.checkedWareHouse = this.frameList
        .filter((item) => item.checked)
        .map((item) => item.id)
    },
    itemInputChange(item) {
      this.checkedWareHouse = this.frameList
        .filter((item) => item.checked)
        .map((item) => item.id)
      this.checkAll =
        this.checkedWareHouse.length ===
        this.frameList.filter((item) => item.stateCode === 'rk').length
    },
    submit() {
      if (this.checkedWareHouse.length === 0) {
        this.$toast.success('请勾选包裹')
        return false
      }
      this.msgTilte = '关于提交打包说明'
      this.megVisible = true
    },
    // goOn() {
    //   this.$reqGet(
    //     orderInfo.toPackage + '?oddNums=' + this.checkedWareHouse.join(','),
    //     '',
    //     {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   ).then((res) => {
    //     if (res.data.code === 200) {
    //       sessionStorage.orderPackageInfo = JSON.stringify(res.data.data)
    //       this.$router.push('/to-package')
    //     }
    //   })
    goOn() {
      this.$reqGet(
        orderInfo.toPackage + '?oddNums=' + this.checkedWareHouse.join(','),
        '',
        {
          'Content-Type': 'multipart/form-data'
        }
      ).then((res) => {
        if (res.data.code === 200) {
          sessionStorage.orderPackageInfo = JSON.stringify(res.data.data)
          this.$router.push('/to-package')
        } else {
          this.$toast.success(res.data.message)
        }
      })
    },
    deleteItem(item, index) {
      this.$reqDelete(orderInfo.deleteItem + item.id).then((res) => {
        if (res.data.code === 200) {
          this.$toast.success('删除成功')
          this.frameList.splice(index, 1)
        } else {
          this.$toast.erro(res.data.message)
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
@import url("./order.less");
.ware-house-item {
  margin-bottom: 8px;

  display: flex;
  span:nth-child(1) {
    width: 38%;
  }
  span:nth-child(2) {
    flex: 1;
  }
}
</style>
